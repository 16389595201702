import React from 'react'

function AppFooter() {
  return (
    <>
      <section className="WhyChooseUs no-bg section-bg section">
        <div className="container">
          <div className="row fun-facts pt-4" style={{ alignItems: "center" }}>
            <div className="col-lg-3 col-md-6 col-6" style={{ borderRight: "3px solid #273a47" }}>
              <div style={{ color: "#273a47", textAlign: "center", padding: "15px 0" }}>
                <h3>Why Trust Us?</h3>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-6">
              <div className="single-fact">
                <div className="number"><span className="counter">7</span>+</div>
                <p>Years of Success</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-6">
              <div className="single-fact">
                <div className="number"><span className="counter">278</span>+</div>
                <p>Completed Projects</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-6">
              <div className="single-fact">
                <div className="number"><span className="counter">99.9</span>%</div>
                <p>Satisfied Clients</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer className="footer section">
        <div className="footer-top"></div>
        <div className="footer-bottom">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="bottom-head">
                  <div className="row">
                    <div className="col-sm-4">
                      <ul className="social">
                        <li><a href="https://www.facebook.com/digitalyuga" target="_blank" rel="noreferrer"><i className="fa fa-facebook"></i></a></li>
                        <li><a href="https://twitter.com/digitalyugabpl" target="_blank" rel="noreferrer"><i className="fa fa-twitter"></i></a></li>
                        <li><a href="https://www.linkedin.com/company/digitalyuga-it-services/" target="_blank" rel="noreferrer"><i className="fa fa-linkedin"></i></a></li>
                        <li><a href="https://www.youtube.com/channel/UCsRSF9AeKD9MXFMcrk6LGkw/videos" target="_blank" rel="noreferrer"><i className="fa fa-youtube-play"></i></a></li>
                      </ul>
                    </div>
                    <div className="col-sm-4">
                      <div className="copyright">
                        <p className="copyright">Copyright © 2022 Digitalyuga</p>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="copyright">
                        <a href="https://digitalyuga.com/index.html"><button className="btn1"><i className="fa fa-home"></i> Home</button></a>
                        <a href="https://digitalyuga.com/contact-us.html"><button className="btn1"><i className="fa fa-phone"></i> Contact</button></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default AppFooter
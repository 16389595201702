import React from 'react'
import AppHeader from './AppHeader'
import AppFooter from './AppFooter'
import AppContent from './AppContent'

function Layout() {
    return (
        <>
            <AppHeader />
            <AppContent />
            <AppFooter />
        </>
    )
}

export default Layout
import React from 'react'
import { Link } from 'react-router-dom'

function AppHeader() {
  const siteURL = "https://digitalyuga.com/"
  return (
    <header className="header">
      <div className="header-inner">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-3 col-12">
              <div className="logo"><a href={siteURL}> <img src={require('../images/logo.png')} alt="logo" /> </a></div>
              <div className="mobile-menu"></div>
            </div>
            <div className="col-lg-9 col-md-9 col-12">
              <div className="header-widget">
                <nav class="navbar navbar-default">
                  <div class="navbar-collapse">
                    <ul id="nav" class="nav menu navbar-nav">
                      <li><a href="#">Digitalyuga<i class="fa fa-angle-down"></i></a>
                        <ul class="dropdown">
                          <li><a href={`${siteURL}about-digitalyuga.html`}>About Digitalyuga</a></li>
                          <li><a href={`${siteURL}team-digitalyuga.html`}>Team Digitalyuga</a></li>
                          <li><a href={`${siteURL}careers.html`}>Career With Digitalyuga</a></li>
                        </ul>
                      </li>
                      <li> <a href="#">Products<i class="fa fa-angle-down"></i></a>
                        <ul class="dropdown">
                          <li><a href="https://easyautomobile.in/" target="_blank">Easy Automobile</a></li>
                          <li><a href="https://easycolonizer.in/" target="_blank">Easy Colonizer</a></li>
                          <li><a href="https://easyinventory.in/" target="_blank">Easy Inventory</a></li>
                          <li><a href="https://ims.digitalyuga.com/" target="_blank">Easy IMS</a></li>
                          <li><a href="https://dycrm.digitalyuga.com/" target="_blank">DY CRM</a></li>
                        </ul>
                      </li>
                      <li><a href="#">Services<i class="fa fa-angle-down"></i></a>
                        <ul class="dropdown">
                          <li><a href={`${siteURL}software-consulting-services.html`}>Software Consulting</a></li>
                          <li><a href={`${siteURL}software-development-bhopal.html`}>Software Development<i class="fa fa-angle-double-right"></i></a>
                            <ul class="dropdown submenu">
                              <li><a href={`${siteURL}software-product-development.html`}>Product Development</a></li>
                              <li><a href={`${siteURL}custom-software-development.html`}>Custom Development</a></li>
                              <li><a href={`${siteURL}website-design-bhopal.html`}>Web Development</a></li>
                              <li><a href={`${siteURL}ecommerce-web-development.html`}>E-Commerce Development</a></li>
                              <li><a href={`${siteURL}cms-based-web-development.html`}>CMS Development</a></li>
                            </ul>
                          </li>
                          <li>
                            <a href={`${siteURL}digital-marketing-company-bhopal.html`}>Digital Marketing<i class="fa fa-angle-double-right"></i></a>
                            <ul class="dropdown submenu">
                              <li><a href={`${siteURL}seo-company-in-bhopal.html`}>Search Engine Optimization</a></li>
                              <li><a href={`${siteURL}sem-services.html`}>Search Engine Marketing</a></li>
                              <li><a href={`${siteURL}social-media-optimization.html`}>Social Media Optimization</a></li>
                              <li><a href={`${siteURL}social-media-marketing.html`}>Social Media Marketing</a></li>
                              <li><a href={`${siteURL}content-marketing.html`}>Content Marketing</a></li>
                              <li><a href={`${siteURL}email-marketing.html`}>Email & SMS Marketing</a></li>
                            </ul>
                          </li>
                          <li><a href={`${siteURL}best-web-hosting-company-in-india.html`}>
                            <i class="fa fa-angle-double-right"></i>Web & Cloud Hosting</a>
                            <ul class="dropdown submenu">
                              <li><a href="https://shop.digitalyuga.com/domain-registration/index.php"
                                target="_blank">Domain Registration</a></li>
                              <li><a href="https://shop.digitalyuga.com/web-hosting/index.php"
                                target="_blank">Shared Hosting</a></li>
                              <li><a href="https://shop.digitalyuga.com/cloudhosting.php"
                                target="_blank">Cloud Hosting</a></li>
                              <li><a href="https://shop.digitalyuga.com/optimized-wordpress-hosting.php"
                                target="_blank">WordPress Hosting</a></li>
                              <li><a href="https://shop.digitalyuga.com/business-email"
                                target="_blank">Email Hosting</a></li>
                              <li><a href="https://shop.digitalyuga.com/windows-reseller-hosting.php"
                                target="_blank">Reseller Hosting</a></li>
                              <li><a href="https://shop.digitalyuga.com/dedicated-servers.php"
                                target="_blank">Dedicated Server</a></li>
                            </ul>
                          </li>
                          <li><a href={`${siteURL}software-testing-services.html`}>Software Testing</a></li>
                          <li><a href={`${siteURL}software-maintenance-services.html`}>Software Maintenance</a></li>
                        </ul>
                      </li>
                      <li>
                        <a href={`${siteURL}our-clients.html`}>Portfolio</a>
                      </li>
                      <li class="active"><Link to="/">Updates</Link></li>
                      <li><a href={`${siteURL}contact-us.html`}>Locate Us</a></li>
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default AppHeader
import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Routing from '../Routing'

const loading = (
  <>
    <div className='text-center mt-5'>
      <button className="btn btn-warning">Loading...</button>
    </div>
  </>
)

function AppContent() {
  return (
    <>
      <React.Suspense fallback={loading}>
        <Routes>
          {Routing.map((route, idx) => {
            return (
              route.element && (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  element={route.element}
                />
              )
            )
          })}
        </Routes>
      </React.Suspense>
    </>
  )
}

export default AppContent
import React from "react";

const Home = React.lazy(() => import('./Pages/blogs/Home/Home'))
const Blog28 = React.lazy(() => import('./Pages/blogs/Blog28'))
const Blog27 = React.lazy(() => import('./Pages/blogs/Blog27'))
const Blog26 = React.lazy(() => import('./Pages/blogs/Blog26'))
const Blog25 = React.lazy(() => import('./Pages/blogs/Blog25'))
const Blog24 = React.lazy(() => import('./Pages/blogs/Blog24'))
const Blog23 = React.lazy(() => import('./Pages/blogs/Blog23'))
const Blog22 = React.lazy(() => import('./Pages/blogs/Blog22'))
const Blog21 = React.lazy(() => import('./Pages/blogs/Blog21'))
const Blog20 = React.lazy(() => import('./Pages/blogs/Blog20'))
const Blog19 = React.lazy(() => import('./Pages/blogs/Blog19'))
const Blog18 = React.lazy(() => import('./Pages/blogs/Blog18'))
const Blog17 = React.lazy(() => import('./Pages/blogs/Blog17'))
const Blog16 = React.lazy(() => import('./Pages/blogs/Blog16'))
const Blog15 = React.lazy(() => import('./Pages/blogs/Blog15'))
const Blog14 = React.lazy(() => import('./Pages/blogs/Blog14'))
const Blog13 = React.lazy(() => import('./Pages/blogs/Blog13'))
const Blog12 = React.lazy(() => import('./Pages/blogs/Blog12'))
const Blog11 = React.lazy(() => import('./Pages/blogs/Blog11'))
const Blog10 = React.lazy(() => import('./Pages/blogs/Blog10'))
const Blog9 = React.lazy(() => import('./Pages/blogs/Blog9'))
const Blog8 = React.lazy(() => import('./Pages/blogs/Blog8'))
const Blog7 = React.lazy(() => import('./Pages/blogs/Blog7'))
const Blog6 = React.lazy(() => import('./Pages/blogs/Blog6'))
const Blog5 = React.lazy(() => import('./Pages/blogs/Blog5'))
const Blog4 = React.lazy(() => import('./Pages/blogs/Blog4'))
const Blog3 = React.lazy(() => import('./Pages/blogs/Blog3'))
const Blog2 = React.lazy(() => import('./Pages/blogs/Blog2'))
const Blog1 = React.lazy(() => import('./Pages/blogs/Blog1'))
const NotFound = React.lazy(() => import('./Pages/NotFound'))

const Routing = [
    { path: '/', name: 'home', exact: true, element: <Home /> },
    { path: '/seo-for-small-businesses', name: 'seo-for-small-businesses', exact: true, element: <Blog28 /> },
    { path: '/how-social-media-marketing-can-help-in-generating-revenue-for-the-customer-facing-business', name: 'how-social-media-marketing-can-help-in-generating-revenue-for-the-customer-facing-business', exact: true, element: <Blog27 /> },
    { path: '/how-seo-expert-optimize-google-my-business', name: 'how-seo-expert-optimize-google-my-business', exact: true, element: <Blog26 /> },
    { path: '/top-directory-submission-sites', name: 'top-directory-submission-sites', exact: true, element: <Blog25 /> },
    { path: '/creating-and-optimizing-google-my-business-card', name: 'creating-and-optimizing-google-my-business-card', exact: true, element: <Blog24 /> },
    { path: '/logo-design-techniques-that-redefine-the-mores-of-creativity', name: 'logo-design-techniques-that-redefine-the-mores-of-creativity', exact: true, element: <Blog23 /> },
    { path: '/how-to-create-a-cool-error-404-page-in-wordpress', name: 'how-to-create-a-cool-error-404-page-in-wordpress', exact: true, element: <Blog22 /> },
    { path: '/the-concept-of-marketing-mix-4ps', name: 'the-concept-of-marketing-mix-4ps', exact: true, element: <Blog21 /> },
    { path: '/how-to-get-more-google-reviews', name: 'how-to-get-more-google-reviews', exact: true, element: <Blog20 /> },
    { path: '/why-graphic-design-strategy-is-important-for-your-business', name: 'why-graphic-design-strategy-is-important-for-your-business', exact: true, element: <Blog19 /> },
    { path: '/website-development-service-in-london', name: 'website-development-service-in-london', exact: true, element: <Blog18 /> },
    { path: '/facebook-video-ad-for-business-to-business', name: 'facebook-video-ad-for-business-to-business', exact: true, element: <Blog17 /> },
    { path: '/what-is-the-cms-system-and-its-types', name: 'what-is-the-cms-system-and-its-types', exact: true, element: <Blog16 /> },
    { path: '/why-iphone-application-development-services-are-needed-for-businesses', name: 'why-iphone-application-development-services-are-needed-for-businesses', exact: true, element: <Blog15 /> },
    { path: '/why-to-do-digital-marketing', name: 'why-to-do-digital-marketing', exact: true, element: <Blog14 /> },
    { path: '/school-management-software', name: 'school-management-software', exact: true, element: <Blog13 /> },
    { path: '/google-ranking-factors', name: 'google-ranking-factors', exact: true, element: <Blog12 /> },
    { path: '/website-designing-services', name: 'website-designing-services', exact: true, element: <Blog11 /> },
    { path: '/best-software-development-company-in-bhopal', name: 'best-software-development-company-in-bhopal', exact: true, element: <Blog10 /> },
    { path: '/how-to-select-right-software-development-company', name: 'how-to-select-right-software-development-company', exact: true, element: <Blog9 /> },
    { path: '/5-extremely-effective-seo-facts', name: '5-extremely-effective-seo-facts', exact: true, element: <Blog8 /> },
    { path: '/software-development-life-cycle', name: 'software-development-life-cycle', exact: true, element: <Blog7 /> },
    { path: '/are-you-unable-to-drive-traffic-on-your-website-and-social-media', name: 'are-you-unable-to-drive-traffic-on-your-website-and-social-media', exact: true, element: <Blog6 /> },
    { path: '/what-makes-a-website-necessary-for-your-business', name: 'what-makes-a-website-necessary-for-your-business', exact: true, element: <Blog5 /> },
    { path: '/importance-of-digital-marketing-in-todays-stage-of-affairs', name: 'importance-of-digital-marketing-in-todays-stage-of-affairs', exact: true, element: <Blog4 /> },
    { path: '/g-suites-price-update-2019', name: 'g-suites-price-update-2019', exact: true, element: <Blog3 /> },
    { path: '/what-is-digital-marketing', name: 'what-is-digital-marketing', exact: true, element: <Blog2 /> },
    { path: '/what-is-software-development', name: 'what-is-software-development', exact: true, element: <Blog1 /> },
    { path: '*', name: 'not-found', exact: true, element: <NotFound /> },

]

export default Routing